



































































































































import AgTable from '@/app/shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell.vue";
import AgTableImageCell from '@/app/shared/shared-components/ag-grid/AgTableImageCell.vue';
import utilities from '@/app/shared/utilities/index';
import {MarketModel} from "@/app/ecommerce/services/market-service/interfaces/market.model";
import {VueEditor} from "vue2-editor";
import {SocialMediaLinksInterface} from "@/app/ecommerce/services/market-service/interfaces/social-media-links.interface";
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent.vue";
import {ColDef, GridApi, GridOptions} from "ag-grid-community";
import Vue from "vue";
import TmInput from "@/app/shared/shared-components/TmInput.vue";
import {mapActions, mapState} from "pinia";
import {useMarketStore} from "@/app/ecommerce/views/Markets/market.store";

export default Vue.extend({
  name: "Market",
  components: {
    AgTable,
    VueEditor,
    FilePondComponent,
    TmInput
  },
  data() {
    return {
      isLoading: true,
      gridOptions: {} as GridOptions,
      popupActiveDetails: false,
      popupActive: false,
      dialogTitle: '',
      gridApi: {} as GridApi | null | undefined,
      market: {
        id: undefined,
        name: '',
        logo_url: '',
        desc: '',
        social_media_links: [{
          key: 'twitter',
          value: 'Bassel@twitter.com'
        }] as SocialMediaLinksInterface[],
      } as MarketModel
    };
  },
  computed: {
    ...mapState(useMarketStore, ['markets']),
  },
  methods: {
    ...mapActions(useMarketStore, ['addMarket', 'editMarket', 'fetchMarkets']),
    createColDefs(): Array<ColDef> {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 250,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
          width: 250,
        },
        {
          headerName: 'الشعار',
          field: 'logo_url',
          cellRendererFramework: AgTableImageCell,
          width: 250,
          cellRendererParams: {
            width: '40%'
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          width: 250,
          cellRendererParams: {
            editRecord: function (market: MarketModel) {
              self.editRecord(market);
            },
            viewRecordDetails: function (record: MarketModel) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit']
          },
        },
      ];
    },
    viewRecordDetails(record: MarketModel) {
      this.popupActiveDetails = true;
      this.market = record;
    },
    initFileUpload() {
      (this.$refs.fileUpload as any)?.initValidation();
    },
    fileUploaded(file: string) {
      this.market.logo_url = file;
    },
    fileDeleted() {
      this.market.logo_url = '';
    },
    rerender() {
      this.gridApi?.setRowData(this.markets);
    },
    openPopup() {
      (this.$refs.validationObserver as any)?.reset();
      this.popupActive = true;
    },
    createRecord() {
      utilities.initObjectMembers(this.market);
      this.dialogTitle = 'اضافة متجر';
      this.openPopup();
    },
    editRecord(market: MarketModel) {
      this.dialogTitle = 'تعديل متجر';
      Object.assign(this.market, market);
      this.openPopup();
    },
    async onSubmit() {
      this.isLoading = true;
      if (this.market.id !== undefined)
        await this.editMarket(this.market);
      else
        await this.addMarket(this.market);
      this.popupActive = false;
      this.isLoading = false;
      this.initFileUpload();
      this.rerender();
    },
    async fetchAllMarkets() {
      await this.fetchMarkets();
      this.isLoading = false;
    },
  },
  async created() {
    this.gridOptions.columnDefs = this.createColDefs();
    await this.fetchAllMarkets();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
});
